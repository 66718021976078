import {PluginsStoreListSection, PluginsStoreListSectionItem, PluginStoreListLayout} from "shared/plugins";

export const PLUGINS_STORE_LAYOUT = {
  sections: [
    {
      sectionId: "tools",
      title: "Tools",
      items: [
        {
          sectionItemId: "zetaceph",
          title: "Zetaceph",
          path: "zetaceph",
        },
      ],
    },
  ]
};

export const PLUGIN_STORE_LIST_LAYOUT_MAP: Map<string, PluginStoreListLayout> = new Map<string, PluginStoreListLayout>([
  ["zetaceph", {
    listSections: [
      {
        items: [
          {
            pluginUrl: "https://mlplayground-cephtrain-c84b5.web.app/",
          },
          {
            pluginUrl: "https://mlplayground-cephdraw-c84b5.web.app",
          }
        ],
      } as PluginsStoreListSection,
    ]
  } as PluginStoreListLayout],
]);
