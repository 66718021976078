import {PageFragment, PageFragmentProps, PageFragmentState} from "../../../../shared/PageFragment";
import {
  loadPluginStoreListLayout
} from "../types";
import React, {ReactElement} from "react";
import {PluginsStoreBillboard} from "./PluginsStoreBillboard";
import {StyledBoxColumn, StyledContainer, StyledListItem} from "../../../../shared/StyledComponents";
import {Action, PluginIconType, PluginIconUrl} from "../../../../shared/types";
import {RefreshOutlined} from "@mui/icons-material";
import {App} from "../../../App";
import {PluginsStoreDetailsFragment} from "./PluginsStoreDetailsFragment";
import {
  Plugin,
  Plugins,
  PluginsStoreListSectionItem,
  PluginsStoreSectionItem,
  PluginStoreListLayout
} from "../../../../shared/plugins";

export type PluginsStoreListFragmentProps = PageFragmentProps & {
  sectionItem: PluginsStoreSectionItem,
}

type PluginsStoreListFragmentState = PageFragmentState & {
  plugins?: Plugin[],
  layout?: PluginStoreListLayout,
}

export class PluginsStoreListFragment extends PageFragment<PluginsStoreListFragmentProps, PluginsStoreListFragmentState> {

  private readonly plugins = Plugins.getInstance();

  protected onCreateState(): PageFragmentState {
    return {
      ...super.onCreateState(),
      title: this.props.sectionItem.title,
    };
  }

  protected async fetchOnMount(forceReload?: boolean): Promise<void> {
    this.setState({
      plugins: await this.plugins.getOrLoadPlugins(),
      layout: await loadPluginStoreListLayout(this.props.sectionItem.sectionItemId, forceReload),
    });
  }

  createToolbarActions(): Action[] {
    return [
      new Action("Refresh", () => this.reload(true), RefreshOutlined),
    ];
  }

  componentDidUpdate(prevProps: Readonly<PluginsStoreListFragmentProps>, prevState: Readonly<PluginsStoreListFragmentState>, snapshot?: any) {
    if (prevProps.sectionItem !== this.props.sectionItem) {
      this.setState({
        title: this.props.sectionItem.title,
      });
      this.reload();
    }
  }

  renderContent(): ReactElement {
    if (!this.state.layout) {
      return;
    }
    return <StyledBoxColumn>
      <PluginsStoreBillboard items={[]}/>
      <StyledContainer>
        {this.state.layout.listSections.map(listSection => listSection.items.map(item => this.renderItem(item)))}
      </StyledContainer>
    </StyledBoxColumn>;
  }

  private renderItem(item: PluginsStoreListSectionItem) {
    if (!item.pluginManifest) {
      return null;
    }
    const iconUrl = PluginIconUrl(item.pluginManifest);
    return <StyledListItem
      accessory={this.renderAccessory(item)}
      title={item.pluginManifest.name}
      text={item.pluginManifest.description}
      img={iconUrl}
      icon={!iconUrl && PluginIconType(item.pluginManifest)}
      onClick={() => this.showDetails(item)}
    />
  }

  private showDetails(item: PluginsStoreListSectionItem) {
    App.CONTEXT.showDialog(null, () =>
      <PluginsStoreDetailsFragment
        path={this.props.path}
        pluginManifest={item.pluginManifest}/>);
  }

  private renderAccessory(item: PluginsStoreListSectionItem) {
    const actions: Action[] = [];
    if (this.state.plugins?.find(plugin => plugin.url === item.pluginUrl)) {
      actions.push(new Action("Remove", () => this.plugins.removePluginUrl(item.pluginUrl)));
    }
    actions.push(new Action("View details", () => this.showDetails(item)));
    return this.renderAccessoryMore(actions);
  }
}
