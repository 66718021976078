import {JsonObject} from "shared/json/json-object";
import {JsonProperty} from "shared/json/json-property";
import {JSON_OBJECT} from "shared/json/helpers";
import {BaseListItem, BaseListItemsLoader} from "shared/types";

export enum AppStatus {
  NONE = "none",
  PURCHASED = "purchased",
}

@JsonObject()
export class App extends BaseListItem {

  @JsonProperty()
  status: AppStatus = AppStatus.NONE;

  constructor(id: string, creator: string, created: number) {
    super(id, creator, created);
  }
}

export class Apps extends BaseListItemsLoader<App> {

  private static instance;

  static getInstance(): Apps {
    if (!this.instance) {
      this.instance = new Apps();
    }
    return this.instance;
  }

  protected basePath(): string {
    return "apps";
  }

  protected deserializeItem(value: any): App {
    return JSON_OBJECT.deserializeObject(value, App);
  }

  protected serializeItem(item: App): any {
    return JSON_OBJECT.serializeObject(item);
  }

  protected sortOrder(item1: App, item2: App): number {
    return item2.created - item1.created;
  }
}