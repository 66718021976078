import React from "react";
import {Box, Card, Pagination, Typography} from "@mui/material";
import {PD_XLG} from "shared/dimens";
import {StyledBoxColumn} from "../../../../shared/StyledComponents";
import {PluginsStoreBillboardItem} from "../types";

export type PluginsStoreBillboardProps = {
  items: PluginsStoreBillboardItem[],
  style?: any,
}

export type PluginsStoreBillboardState = {
  selectedIndex: number,
}

export class PluginsStoreBillboard extends React.PureComponent<PluginsStoreBillboardProps, PluginsStoreBillboardState> {

  constructor(props: PluginsStoreBillboardProps, context: any) {
    super(props, context);
    this.state = {
      selectedIndex: 0,
    };
  }

  render() {
    if (this.props.items.length <= 0) {
      return null;
    }
    const selectedItem = this.props.items[this.state.selectedIndex];
    return <StyledBoxColumn style={{...this.props.style}}>
      <Box style={{
        display: "flex",
        flexDirection: "column",
        gap: PD_XLG,
      }}>
        <Card style={{width: "100%", aspectRatio: 2}}>
          <img style={{width: "100%", height: "100%"}}/>
        </Card>
        <Pagination count={this.props.items.length} style={{margin: "auto"}}/>
        <Typography style={{
          fontSize: "400%",
          fontWeight: 700,
          lineHeight: 1,
          textAlign: "center",
          margin: "auto",
        }}>{selectedItem.title}</Typography>
        <Typography style={{
          fontSize: "150%",
          fontWeight: 400,
          lineHeight: 1.2,
          opacity: 0.5,
          textAlign: "center",
          margin: "auto",
        }}>{selectedItem.text}</Typography>
      </Box>
    </StyledBoxColumn>;
  }
}
