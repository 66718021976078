import React from "react";
import {
  AccountBoxOutlined,
  AppsOutlined,
  DevicesOutlined,
  HomeOutlined,
  NotificationsOutlined,
} from "@mui/icons-material";
import {TabInfo, Tabs} from "shared/TabsContainer";
import {AppTabsContainer, AppTabsContainerProps, AppTabsContainerState} from "shared/AppTabsContainer";
import {PluginsStoreFragment} from "app/shared/plugins/store/PluginsStoreFragment";
import {PathComponent, PathProps} from "index";
import {Box, Button, Tooltip, Typography} from "@mui/material";
import {PD_SM, PD_XXSM, SZ_XSM} from "shared/dimens";
import {StyledBadge, StyledBoxRow} from "shared/StyledComponents";
import {PluginConfig} from "../shared/types";
import {App, THEME_OPTIONS} from "./App";
import {getLoginCredentials} from "../shared/BaseApp";
import {FIREBASE_CONFIG} from "../consts";
import {BaseFragment, BaseFragmentProps, BaseFragmentState} from "../shared/BaseFragment";
import {PluginConfigProvider, Plugins} from "../shared/plugins";
import {AppPrefs} from "./app_prefs";
import {white} from "../shared/colors";
import {MembersKey} from "../shared/entities";
import {HomeFragment} from "./home/HomeFragment";
import {AppsFragment} from "./apps/AppsFragment";
import {DirectoryFragment} from "./directory/DirectoryFragment";
import {PlaceholderFragment} from "../shared/PlaceholderFragment";

export enum TabType {
  HOME = "home",
  APPS = "apps",
  DIRECTORY = "directory",
  DEVICES = "devices",
  PLUGINSTORE = "pluginsstore",
  PLUGINS = "plugins",
}

function createPluginConfig(id?: string): PluginConfig {
  return {
    app: {
      themeOptions: THEME_OPTIONS,
      firebase: FIREBASE_CONFIG,
    },
    membersKey: MembersKey.from(id),
    autoLogin: getLoginCredentials(),
  }
}

const TABS: Tabs<TabType> = {
  items: [
    {
      type: TabType.HOME,
      path: "home",
      text: "Home",
      iconType: HomeOutlined,
      render: (path: PathProps) => <HomeFragment path={path}/>,
    },
    {
      type: TabType.APPS,
      path: "apps",
      text: "Apps",
      iconType: AppsOutlined,
      render: (path: PathProps) => <AppsFragment path={path}/>,
    },
    {
      type: TabType.DIRECTORY,
      path: "directory",
      text: "Directory",
      iconType: AccountBoxOutlined,
      render: (path: PathProps) => <DirectoryFragment path={path}/>,
    },
    {
      type: TabType.DEVICES,
      path: "devices",
      text: "Devices",
      iconType: DevicesOutlined,
      render: (path: PathProps) => <PlaceholderFragment path={path}/>,
    },
    {
      type: TabType.PLUGINSTORE,
      path: "plugins/store",
      text: "Plugins",
      iconType: AppsOutlined,
      hidden: true,
      nestedPaths: PluginsStoreFragment.nestedPaths(),
      render: (path: PathProps) => <PluginsStoreFragment path={path}/>,
    },
    {
      type: TabType.PLUGINS,
      path: ":plugin_id",
      groupType: "plugins",
      plugins: [],
    },
  ],
  containerId: "main",
};

type MainTabsContainerProps = AppTabsContainerProps<TabType> & {}

type MainTabsContainerState = AppTabsContainerState<TabType> & {}

class MainTabsContainer extends AppTabsContainer<TabType, MainTabsContainerProps, MainTabsContainerState> {

  protected renderToolbar(): React.ReactElement {
    return <Box style={{
      display: "flex",
      paddingLeft: PD_SM,
      paddingRight: PD_SM,
      alignItems: "center",
      position: "relative",
    }}>
      <Box style={{display: "flex"}}>
        <Box style={{display: "flex", alignItems: "center", background: white, zIndex: 1}}>
          <img src={"/logotype.png"} style={{height: SZ_XSM}}/>
          <Typography variant="h5" style={{ paddingLeft: PD_SM, paddingRight: PD_SM, paddingTop: PD_XXSM, paddingBottom: PD_XXSM, maxWidth: 240, overflow: "hidden", whiteSpace:"nowrap", textOverflow: "ellipsis"}}>{App.CONTEXT.getAppConfig().stampText}</Typography>
        </Box>
      </Box>
      <span style={{flexGrow: 1}}/>
      <StyledBoxRow>
        <Tooltip title="Notifications">
          <StyledBadge badgeContent={1}>
            <Button onClick={() => this.props.path.navigate("/notifications")}>
              <NotificationsOutlined/>
            </Button>
          </StyledBadge>
        </Tooltip>
      </StyledBoxRow>
    </Box>;
  }
}

export type MainProps = BaseFragmentProps & {}

type MainState = BaseFragmentState & {}

export class Main extends BaseFragment<MainProps, MainState> implements PluginConfigProvider {

  private readonly appPrefs = AppPrefs.getInstance();

  private readonly plugins = Plugins.initInstance(this.appPrefs.getPluginUrls(), this);

  constructor(props: MainProps, context: any) {
    super(props, context);
    this.state = {}
  }

  static nestedPaths(): PathComponent[] {
    return MainTabsContainer.nestedPathsFromTabs(TABS);
  }

  getPluginConfig() {
    return createPluginConfig();
  }

  protected async fetchOnMount(): Promise<void> {
    const tabInfo: TabInfo<TabType> = TABS.items.find(item => item.type === TabType.PLUGINS);
    tabInfo.plugins = await this.plugins.getOrLoadPlugins();
  }

  protected renderContainerContent(): React.ReactElement | null {
    return <MainTabsContainer logo="/icon_w.png" path={this.props.path} tabs={TABS}/>;
  }
}
