export const FIREBASE_CONFIG = {
  apiKey: "AIzaSyB2HtFeY9XBq3EJFiGLM1c0BGeaa5rvi-k",
  authDomain: "praxis-6b6a8.firebaseapp.com",
  databaseURL: "https://praxis-6b6a8-default-rtdb.firebaseio.com",
  projectId: "praxis-6b6a8",
  storageBucket: "praxis-6b6a8.appspot.com",
  messagingSenderId: "516717977820",
  appId: "1:516717977820:web:cbd19e9a8723d82a81d419",
  measurementId: "G-ME13MESMLC"
};

export const THEME_COLOR_PRIMARY = "#003366";
export const THEME_COLOR_PRIMARY_CONTRAST = "#fff";
export const THEME_COLOR_SECONDARY = "#fff";
export const THEME_COLOR_SECONDARY_CONTRAST = "#000";

export const HOST_ID = "fynedental_inhouse";
