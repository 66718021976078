import {PluginManifests} from "shared/plugins";
import {BaseApp} from "shared/BaseApp";

export type PluginsStoreBillboardItem = {
  title: string,
  text: string,
  img: string,
}

export async function loadPluginStoreListLayout(sectionItemId: string, forceReload?: boolean) {
  const layout = BaseApp.CONTEXT.getAppConfig().pluginStoreConfig?.listLayoutMap.get(sectionItemId);
  if (layout) {
    for (const listSection of layout.listSections) {
      for (const listSectionItem of listSection.items) {
        listSectionItem.pluginManifest = await PluginManifests.getInstance().getOrLoadPluginManifest(listSectionItem.pluginUrl);
      }
    }
  }
  return layout;
}
