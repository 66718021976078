import {PageFragment, PageFragmentProps, PageFragmentState} from "shared/PageFragment";
import {App, Apps, AppStatus} from "./types";
import {TabInfo} from "shared/TabsContainer";
import React, {ReactElement} from "react";
import {PlaceholderFragment} from "shared/PlaceholderFragment";
import {$KTS, KeyTextString, OnListItemsListener} from "shared/types";
import {PathComponent} from "index";
import {getMemberAuth} from "shared/auth";
import {Box, Button, Grid, Typography} from "@mui/material";
import {BaseFragment, BaseFragmentProps} from "../../shared/BaseFragment";
import {StyledBoxColumn, StyledBoxRow} from "../../shared/StyledComponents";
import {BORDER_RADIUS, DIVIDER, PD_LG, PD_MD} from "../../shared/dimens";
import {PageWithSidebarContainer, PageWithSidebarContainerRenderer} from "../../shared/PageWithSidebarContainer";
import {Sidebar, SidebarItem, SidebarListener, SidebarTab} from "../../shared/Sidebar";

enum AppCategory {
  ALL = "all",
  TOOLS = "tools",
  PRODUCTIVITY = "productivity",
}

const APP_CATEGORIES: KeyTextString[] = [
  $KTS(AppCategory.ALL, "All"),
  $KTS(AppCategory.TOOLS, "Tools"),
  $KTS(AppCategory.PRODUCTIVITY, "Productivity"),
];

type AppMetadata = {
  id: string,
  title: string,
  link: string,
  iconUrl: string,
  text: string,
  description: string,
  category: string,
  latestVersion: string,
}

const APP_METADATA: AppMetadata[] = [
  {
    id: "cmsblog-admin",
    title: "CMS Blog Admin",
    link: "https://cmsblog-admin.fynedental.net",
    iconUrl: "/assets/apps/cmsblog-admin.png",
    text: "Content management for announcements & news",
    description: "Easy to use dashboard for fast-changing content on your website.",
    category: AppCategory.TOOLS,
    latestVersion: "v1.0.0",
  },
  {
    id: "gmail",
    title: "Gmail",
    link: "https://mail.google.com/a/fynedental.com",
    iconUrl: "/assets/apps/gmail.png",
    text: "Secure, smart, and easy to use email",
    description: "Get more done with Gmail. Now integrated with Google Chat, Google Meet, and more, all in one place.",
    category: AppCategory.PRODUCTIVITY,
    latestVersion: "v1.0.0",
  },
  {
    id: "gcalendar",
    title: "Calendar",
    link: "https://calendar.google.com/a/fynedental.com",
    iconUrl: "/assets/apps/gcalendar.png",
    text: "Shareable Online Calendar",
    description: "Spend less time planning and more time doing with a shareable calendar that works across Google Workspace..",
    category: AppCategory.PRODUCTIVITY,
    latestVersion: "v1.0.0",
  },
  {
    id: "gdrive",
    title: "Google Drive",
    link: "https://drived.google.com/a/fynedental.com",
    iconUrl: "/assets/apps/gdrive.png",
    text: "Easy and secure access to your content",
    description: "Store, share, and collaborate on files and folders from your mobile device, tablet, or computer.",
    category: AppCategory.PRODUCTIVITY,
    latestVersion: "v1.0.0",
  },
  {
    id: "gdocs",
    title: "Docs",
    link: "https://docs.google.com/a/fynedental.com",
    iconUrl: "/assets/apps/gdocs.png",
    text: "Build your best ideas together, in Google Docs",
    description: "Create and collaborate on online documents in real-time and from any device.",
    category: AppCategory.PRODUCTIVITY,
    latestVersion: "v1.0.0",
  },
  {
    id: "gslides",
    title: "Slides",
    link: "https://slides.google.com/a/fynedental.com",
    iconUrl: "/assets/apps/gslides.png",
    text: "Build beautiful presentations together",
    description: "Create and deliver impactful presentations in your browser, from anywhere - no installation required.",
    category: AppCategory.PRODUCTIVITY,
    latestVersion: "v1.0.0",
  },
];

type AppContentItem = {
  id: string,
  status: AppStatus,
}

type AppsContentFragmentProps = BaseFragmentProps & {
  items: AppContentItem[],
}

class AppsContentFragment extends BaseFragment<AppsContentFragmentProps> {

  protected renderContainerContent(): React.ReactElement | null {
    return <StyledBoxColumn style={{padding: PD_LG}}>
      <Grid container>
        {this.props.items.map(item => this.renderItem(item))}
      </Grid>
    </StyledBoxColumn>;
  }

  private renderItem(item: AppContentItem): ReactElement {
    const metadata = APP_METADATA.find(metadata => metadata.id === item.id);
    return <Grid item xs={12} sm={6} md={4}>
      <StyledBoxColumn style={{border: DIVIDER, margin: PD_MD, padding: PD_MD, gap: PD_MD}}>
        <a href={metadata.link}>
          <StyledBoxColumn style={{gap: PD_MD}}>
            <Typography variant="h5">{metadata.title}</Typography>
            <Typography style={{fontWeight: "bold"}}>{metadata.text}</Typography>
            <Typography style={{height: "4rem"}}>{metadata.description}</Typography>
            <StyledBoxRow style={{gap: PD_LG}}>
              <Box style={{width: 96, height: 96, border: DIVIDER, borderRadius: BORDER_RADIUS}}>
                <img src={metadata.iconUrl}
                     style={{width: "100%", height: "100%", objectFit: "cover", borderRadius: BORDER_RADIUS}}/>
              </Box>
              <StyledBoxColumn style={{gap: 0}}>
                <Typography variant="body2">{APP_CATEGORIES.find(kt => kt.key === metadata.category).text}</Typography>
                <Typography variant="caption">{metadata.latestVersion}</Typography>
              </StyledBoxColumn>
            </StyledBoxRow>
          </StyledBoxColumn>
        </a>
        <StyledBoxRow style={{justifyContent: "flex-end"}}>
          <Button variant="contained">Add to My Apps</Button>
        </StyledBoxRow>
      </StyledBoxColumn>
    </Grid>;
  }
}

type AppsCategoriesFragmentProps = PageFragmentProps & {
  items: AppContentItem[],
}

type AppsCategoriesFragmentState = PageFragmentState & {
  selectedCategory?: string,
  items: AppContentItem[],
}

class AppsCategoriesFragment extends PageFragment<AppsCategoriesFragmentProps, AppsCategoriesFragmentState> implements PageWithSidebarContainerRenderer, SidebarListener {

  private readonly sidebarItems: SidebarItem[] = APP_CATEGORIES.map(cat => ({
    title: cat.text,
    object: cat.key,
  }));

  protected async fetchOnMount(forceReload?: boolean): Promise<void> {
    this.setState({
      selectedCategory: AppCategory.ALL,
    });
    return super.fetchOnMount(forceReload);
  }

  componentDidUpdate(prevProps: Readonly<AppsCategoriesFragmentProps>, prevState: Readonly<AppsCategoriesFragmentState>, snapshot?: any) {
    if (prevState.selectedCategory !== this.state.selectedCategory) {
      this.setState({
        items: this.props.items.filter(item => this.state.selectedCategory === AppCategory.ALL || this.state.selectedCategory === APP_METADATA.find(metadata => metadata.id === item.id).category),
      });
    }
  }

  onSelectionChanged(item: SidebarItem) {
    this.setState({
      selectedCategory: item.object,
    });
  }

  renderContent(): React.ReactElement {
    return <PageWithSidebarContainer renderer={this}/>;
  }

  private getSelectedSidebarItem(): SidebarItem {
    return this.sidebarItems?.find(item => item.object as string === this.state.selectedCategory);
  }

  renderPageWithSidebarContainerSidebar(): ReactElement {
    return <Sidebar tabs={[{
      id: "default",
      selected: this.getSelectedSidebarItem(),
      items: {
        groups: [
          {
            items: this.sidebarItems,
            title: "Categories",
            variant: "condensed",
          },
        ],
        containerId: "content",
      },
      listener: this,
    }]}/>;
  }


  renderPageWithSidebarContainerContent(): ReactElement {
    return <AppsContentFragment items={this.state.items}/>;
  }
}

enum AppsTabType {
  ALL = "all",
  MY = "my",
}

const TABS: TabInfo<AppsTabType>[] = [
  {
    type: AppsTabType.MY,
    text: "My Apps",
  },
  {
    type: AppsTabType.ALL,
    text: "All Apps",
  },
];

export interface AppsFragmentListener {
  onAppSelected(app: App);
}

export type AppsFragmentProps = PageFragmentProps & {
  listener?: AppsFragmentListener,
}

type AppsFragmentState = PageFragmentState & {}

export class AppsFragment extends PageFragment<AppsFragmentProps, AppsFragmentState> implements OnListItemsListener<App> {

  private readonly apps = Apps.getInstance();
  private readonly memberAuth = getMemberAuth();

  private readonly allAppItems: AppContentItem[] = APP_METADATA.map(metadata => {
    return {
      id: metadata.id,
      status: AppStatus.NONE,
    };
  });

  static nestedPaths(): PathComponent[] {
    return [];
  }

  protected onCreateState(): AppsFragmentState {
    return {
      ...super.onCreateState(),
      title: "Apps",
      selectedToolbarTabId: AppsTabType.ALL,
    };
  }

  componentDidMount() {
    super.componentDidMount();
    this.apps.registerObserver(this);
  }

  componentWillUnmount() {
    this.apps.unregisterObserver(this);
  }

  onItemChanged(item: App) {
    this.forceUpdate();
  }

  protected async fetchOnMount(): Promise<void> {
    await this.apps.loadListItems();
  }

  getToolbarTabs(): TabInfo<AppsTabType>[] {
    return TABS;
  }

  renderContent(): ReactElement {
    const tabInfo = TABS.find(tab => tab.type === this.state.selectedToolbarTabId);
    // const listItems = this.apps.getListItems();
    switch (tabInfo.type) {
      case AppsTabType.ALL:
        return <AppsCategoriesFragment items={this.allAppItems}/>;
      case AppsTabType.MY:
        return <AppsContentFragment items={this.allAppItems.filter(item => item.status === AppStatus.PURCHASED)}/>;
    }
    return <PlaceholderFragment path={this.props.path}/>
  }
}