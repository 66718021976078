import {DialogFragment, PageFragmentProps, PageFragmentState} from "../../../../shared/PageFragment";
import {
  DialogWithSidebarContainer,
  PageWithSidebarContainerRenderer
} from "../../../../shared/PageWithSidebarContainer";
import React, {ReactElement, useState} from "react";
import {PluginManifest} from "../../../../shared/types";
import {DW_XXL, PD_XLG, SZ_XXLG} from "../../../../shared/dimens";
import {StyledBoxColumn} from "../../../../shared/StyledComponents";
import {Button, Card, Typography} from "@mui/material";
import {Sidebar, SidebarItem} from "../../../../shared/Sidebar";
import {OpenInBrowserOutlined} from "@mui/icons-material";
import {TitleBar} from "../../../../shared/TitleBar";
import {AppPrefs} from "../../../app_prefs";
import {BaseApp, renderPluginIcon} from "../../../../shared/BaseApp";

function createResourcesSidebarItems(pluginManifest: PluginManifest): SidebarItem[] {
  const items: SidebarItem[] = [];
  if (pluginManifest.websiteUrl) {
    items.push({
      title: "Website",
      iconType: OpenInBrowserOutlined,
    });
  }
  return items;
}

function PluginsStoreDetailsSidebar(props: { pluginManifest: PluginManifest }) {
  const resourcesSidebarItems = createResourcesSidebarItems(props.pluginManifest);
  const appPrefs = AppPrefs.getInstance();
  const [added, setAdded] = useState(appPrefs.hasPluginUrl(props.pluginManifest.pluginUrl));
  return <StyledBoxColumn gutter style={{gap: PD_XLG}}>
    <Card style={{marginTop: PD_XLG, width: SZ_XXLG, height: SZ_XXLG, alignSelf: "center"}}>
      {renderPluginIcon(props.pluginManifest)}
    </Card>
    <StyledBoxColumn>
      {!added
        ? <Button variant="contained" onClick={() => {
          appPrefs.addPluginUrl(props.pluginManifest.pluginUrl);
          BaseApp.CONTEXT.showToast("Plugin added.");
          setAdded(true);
        }}>Add</Button>
        : null}
      {added
        ? <Button variant="outlined" color="error" onClick={() => {
          appPrefs.removePluginUrl(props.pluginManifest.pluginUrl);
          BaseApp.CONTEXT.showToast("Plugin removed.");
          setAdded(false);
        }}>Remove</Button>
        : null}
      <Button variant="outlined">Learn more</Button>
    </StyledBoxColumn>
    {resourcesSidebarItems.length > 0
      ? <StyledBoxColumn>
        <Typography variant="h6">Resources</Typography>
        {Sidebar.renderSidebarItemsCondensed(null, resourcesSidebarItems, null)}
      </StyledBoxColumn>
      : null}
  </StyledBoxColumn>
}

function PluginsStoreDetailsContent(props: { pluginManifest: PluginManifest }) {
  return <StyledBoxColumn>
    <TitleBar variant="decorated" text={props.pluginManifest.name}/>
    <StyledBoxColumn gutter style={{gap: PD_XLG}}>
      <StyledBoxColumn>
        <Typography variant="h6">Description</Typography>
        <Typography>{props.pluginManifest.description}</Typography>
      </StyledBoxColumn>
    </StyledBoxColumn>
  </StyledBoxColumn>
}

export type PluginsStoreDetailsFragmentProps = PageFragmentProps & {
  pluginManifest: PluginManifest,
}

type PluginsStoreDetailsFragmentState = PageFragmentState & {}

export class PluginsStoreDetailsFragment extends DialogFragment<PluginsStoreDetailsFragmentProps, PluginsStoreDetailsFragmentState> implements PageWithSidebarContainerRenderer {

  renderContent(): ReactElement {
    return <DialogWithSidebarContainer
      title={this.props.pluginManifest.name}
      path={this.props.path}
      style={{width: DW_XXL, height: "90vh"}}
      renderer={this}
    />;
  }

  renderPageWithSidebarContainerContent(): React.ReactElement {
    return <PluginsStoreDetailsContent pluginManifest={this.props.pluginManifest}/>;
  }

  renderPageWithSidebarContainerSidebar(): React.ReactElement {
    return <PluginsStoreDetailsSidebar pluginManifest={this.props.pluginManifest}/>;
  }
}
