import React, {ReactElement} from "react";
import {BaseFragment} from "../../../../shared/BaseFragment";
import {PageWithSidebarContainer} from "../../../../shared/PageWithSidebarContainer";
import {PathComponent, PathProps} from "../../../../index";
import {SidebarItems} from "shared/Sidebar";
import {PluginsStoreListFragment} from "./PluginsStoreListFragment";
import {Action} from "../../../../shared/types";
import {BaseApp} from "../../../../shared/BaseApp";

export class PluginsStoreFragment extends BaseFragment {

  static nestedPaths(): PathComponent[] {
    return [
      {
        path: ":store_item_id",
        handle: {containerId: "pluginsstore", path: ":store_item_id"},
        render: pathProps => <PluginsStoreListFragment
          path={pathProps}
          sectionItem={PluginsStoreFragment.getItemById(pathProps.params.store_item_id)}/>,
      },
    ];
  }

  static getItemById(storeItemId: string) {
    const layout = BaseApp.CONTEXT.getAppConfig().pluginStoreConfig?.layout;
    return layout?.sections.flatMap(section => section.items).find(item => item.path = storeItemId);
  }

  private createSidebarItems(): SidebarItems {
    const layout = BaseApp.CONTEXT.getAppConfig().pluginStoreConfig?.layout;
    return {
      groups: layout?.sections.map(section => {
          return {
            items: section.items.map(item => {
              return {
                path: item.path,
                title: item.title,
                render: (pathProps: PathProps) => <PluginsStoreListFragment path={pathProps} sectionItem={item}/>,
              };
            }),
            title: section.title,
          }
        },
      ),
      title: "Store",
      containerId: "pluginsstore",
      variant: "condensed",
    }
  }

  protected renderContainerContent(): ReactElement | null {
    return <PageWithSidebarContainer
      title="Plugins"
      path={this.props.path}
      sidebarItems={this.createSidebarItems()}
      sidebarAction={new Action("← Back", () => this.props.path.navigate(-1))}
    />;
  }
}
